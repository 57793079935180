<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </ly-searchList>
   <div class="m-t1 tableBox">
    <ly-table :columns="columnsOther"   ref="tableRef" size="small" :data="data" rowKey="instanceId" :pagination="pagination" @pageChange="pageChange" :loading="loading">
      <template #operation="{ record }">
        <a @click="customRow(record)">详情</a>
      </template>
    </ly-table>
   </div>
    <a-modal v-model:visible="modalVisible" title="详情" width="80%" :maskClosable="false" footer="" destroyOnClose centered :bodyStyle="{ height: '70vh', overflow: 'auto', background: '#f0f2f5', padding: '0 24px', }">
      <FlowDetails :flowId="flowId" v-if="modalVisible" :type="3"></FlowDetails>
    </a-modal>
  </div>
</template>
  
<script setup>
import { onMounted, ref } from 'vue'
import FlowDetails from '../components/flowDetails.vue'
import { copyPage } from '@/api/flow/flow'
const modalVisible = ref(false)
const flowId = ref('')
const data = ref([])
const loading = ref(false)
const searchMsg = ref({})
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqCopyPeople()
}
const searchAdd = (e) => {
   searchMsg.value = e
   pagination.value.current = 1
   reqCopyPeople()
}
const resetBtn = () => {
  searchMsg.value = {}
   pagination.value.current = 1
   reqCopyPeople()
}
const customRow = record => {
  flowId.value = record.flowInstanceId
  modalVisible.value = true
}

const reqCopyPeople = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  copyPage(msg).then(res => {
    if(res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res)
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  reqCopyPeople()
})
const iptData = ref([
  {
    type: 'input',
    placeholder: '抄送人',
    value: null,
    prop: 'creator',
    width: '200px',
  },
])

const columnsOther = ref([
  { title: '序号', width: 50, slots: { customRender: 'sort' } },
  {
    title: '流程实例ID',
    dataIndex: 'flowInstanceId',
    align: 'center',
    key: 'flowInstanceId'
  },
  {
    title: '审批名称',
    dataIndex: 'instanceName',
    align: 'center',
    key: 'instanceName'
  },
  {
    title: '发起人',
    align: 'center',
    width: '5%',
    dataIndex: 'sponsor'
  },
  {
    title: '发起时间',
    align: 'center',
    dataIndex: 'createTime'
  },
  {
    title: '流程状态',
    align: 'center',
    dataIndex: 'flowInstanceStatus.label'
  },
  {
    title: '抄送人',
    dataIndex: 'creator',
    align: 'center',
    width: '5%',
    key: 'creator'
  },
  {
    title: '抄送内容',
    align: 'center',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '抄送时间',
    align: 'center',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '5%',
    align: 'center',
    dataIndex: 'content',
    key: 'content',
    slots: {
      customRender: 'operation'
    }
  }
])

</script>

<style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
</style>